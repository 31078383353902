import { useState, useEffect } from 'react';
import axios from 'axios';

export default function useAuth(code) {
    const [accessToken, setAccessToken] = useState();
    const [refreshToken, setRefreshToken] = useState();
    const [expiresIn, setExpiresIn] = useState();

    useEffect(() => { 
        if (!code) return;
        axios.post("http://localhost:3001/login/", {
            code,
        }).then(res => {
            setAccessToken(res.data.accessToken);
            setRefreshToken(res.data.refreshToken);
            setExpiresIn(res.data.expiresIn);
            window.history.pushState({}, null, "/");    // cleans up url
        }).catch(err => {
            // if error is thrown, return to login page
            // console.log(err)
            window.location = "/"
        })
    }, [code])

    useEffect(() => {
        if (!refreshToken || !expiresIn) { return; }
        const interval = setInterval(() => { 
            axios.post("http://localhost:3001/refresh/", {
                method: "POST",
                body: {
                    refreshToken,
                },
            }).then(res => { 
                setAccessToken(res.data.accessToken);
                setExpiresIn(res.data.expiresIn);
            }).catch(err => {
                // if error is thrown, return to login page
                // console.log(err);
                window.location = "/"
            })
        }, (expiresIn - 60) * 1000)

        return () => clearInterval(interval)
    }, [refreshToken, expiresIn])

    return accessToken;
}